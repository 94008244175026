@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.login-modal {
  color: red;

  :global {
    .ant-modal-content {
      padding: 0;
      border-radius: 28px;
    }
  }

  .login-content {
    display: flex;

    .img {
      .size(261px,508px);
    }

    .right {
      flex: 1;

      .flex-col();

      .title {
        .flex-row();
        justify-content: space-evenly;
        .wx {
          width: 22px;
          height: 22px;
        }

        .wx-text {
          margin-left: 8px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 18px;
          color: #2c2c2c;
          text-align: left;
          font-style: normal;
        }
      }

      .qr-wrap {
        margin-top: 32px;
        width: 252px;
        height: 252px;
        background: #ffffff;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        .flex-row();
        position: relative;

        .qr-code {
          .size(212px,214px);
        }

        .loading-icon {
          .size(24px);
        }
        .mask-box{
          .size(100%);
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255, 255, 255, .95);
          position: absolute;
          top: 0;
          left: 0;
          font-weight: 400;
          font-size: 13px;
          color: #353535;
          line-height: 18px;
          text-align: center;
        }
      }

      .tips {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #333333;
        margin-top: 14px;

        .checkbox-btn {
          margin-right: 2px;
          padding: 4px;
          cursor: pointer;

          &.checkbox-active {
            .check-hover, .check-normal {
              display: none;
            }

            .check-active {
              display: block
            }
          }

          &:not(.checkbox-active):hover {
            .check-hover {
              display: block
            }

            .check-normal {
              display: none
            }
          }

          img {
            .size(14px);
            display: block;
          }

          .check-hover, .check-active {
            display: none;
          }
        }

        a {
          color: rgba(69, 154, 233, 1) !important;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      .info {
        margin-top: 10px;
        width: 342px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 17px;
        text-align: center;
        font-style: normal;

        .link {
          color: #666666;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
