@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.welfare-count-down-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 14px;
  font-size: 13px;
  color: #FFFFFF;
  width: fit-content;
  background: linear-gradient(176deg, #FF7ADC 0%, #FF57D3 100%);
  position: relative;
  border-radius: 8px;
  line-height: 18px;
  font-weight: normal;

  &::after{
    content: '';
    .pseudo-arrow-down(10px,#FF57D3);
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
  }
  .count {
    width: 32px;
    height: 32px;
    background: linear-gradient(160deg, #FFE226 0%, #FFDF5E 100%);
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.65);
    .flex-row();
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin: 0 5px;
  }
}