@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.pay-qr-dialog {
  :global {

    .ant-modal {
      line-height: unset !important;
    }

    .ant-modal-content {
      padding: 0px !important;
      border-radius: 28px !important;

      .ant-modal-body {
        line-height: unset !important;
      }
    }
  }

  .pay-qr-dialog-content {
    padding: 24px 0;
    height: 476px;
    .flex-col();
    justify-content: flex-start;
    background: linear-gradient(180deg, #F3EFFA 0%, #FFFFFF 100%);
    box-shadow: 0px 5px 32px 2px rgba(0, 0, 0, 0.12);
    border-radius: 28px;
    border: 1px solid #FFFFFF;

    >.title {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      line-height: 28px;
    }

    >.desc {
      align-items: center;

      .wechat-icon {
        width: 18px;
      }

      font-size: 14px;
      font-family: PingFangSC-Regular,
      PingFang SC;
      font-weight: 400;
      color: #666666;
    }

    .qr-box {
      .size(228px);
      margin-top: 26px;
      .flex-col();
      position: relative;

      >img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      .reload-box {
        .size(100%);
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, 0.94);
        justify-content: center;
        cursor: pointer;

        img {
          width: 16px;
          margin-bottom: 4px;
        }

        span {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 18px;
        }
      }
    }

    .channel-list {
      background: #F8F8F8;
      border-radius: 10px;
      border: 1px solid #E9E9E9;
      .flex-row();
      margin-top: 16px;
      width: fit-content;

      >div {
        padding: 7px 16px;
        .flex-row();
        font-size: 13px;
        font-weight: 400;
        .clickable();
        color: #666666;
        line-height: 18px;
        position: relative;

        &.disable {
          cursor: unset;

          img,
          span {
            opacity: 0.5;

          }

          &:hover {
            .tips {
              display: unset;
            }
          }
        }

        img {
          width: 17px;
          margin-right: 5px;
        }

        &:not(.disable):hover {
          color: #333333;
        }

        &.active {
          background: #FFFFFF;
          box-shadow: 0px 0px 7px 0px rgba(92, 92, 92, 0.08);
          border-radius: 8px;
          color: #333333;
          font-weight: 600;
        }
      }
    }

    >.price-box {
      margin-top: 16px;
      .flex-row();

      .discount-price {
        font-size: 22px;
        font-weight: 600;
        color: @color-primary;
        line-height: 30px;
        margin-right: 8px;
      }

      .original-price {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        text-decoration: line-through;
      }
    }
  }
}