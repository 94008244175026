@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.empty-box {
  .flex-col();

  .icon {
    .size(168px, 104px);
    object-fit: cover;
  }

  .text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4a4a51;
    line-height: 20px;
    margin-top: 28px;
  }

  &.fullscreen {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
