@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.exchange-code-dialog {
  :global {

    .ant-modal {
      line-height: unset !important;
    }

    .ant-modal-content {
      padding: 0 !important;
      border-radius: 28px !important;

      .ant-modal-body {
        line-height: unset !important;
      }
    }
  }

  .exchange-coupon-dialog-content {
    height: 100%;
    padding: 24px 32px;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    text-align: center;
    position: relative;

    .input-box {
      width: 486px;
      height: 54px;
      border-radius: 10px;
      background: #f7f9fa;
      margin-top: 24px;

      input {
        .size(100%);
        border: none;
        outline: none;
        background: transparent;
        font-size: 15px;
        padding-left: 20px;
      }

      &.error {
        border: 1px solid #f7353f;
      }
    }

    .error-tips {
      color: #f72f39;
      font-size: 12px;
      text-align: left;
      margin-top: 8px;
    }

    .btn-box {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: 24px;
      padding-right: 32px;
      left: 0;

      .btn {
        width: 120px;
        height: 44px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #dddddd;
        cursor: pointer;
        font-size: 16px;
        margin-left: 16px;
      }

      .primary-btn {
        color: #ffffff;
        background: @color-primary;
        border: none;
      }
    }

    //兑换成功
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      // height: 100%;
    }

    .success-content {
      position: relative;
      z-index: 2;

      .gift-icon {
        height: 134px;
      }

      .bottom-title {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #482e6f;
        letter-spacing: 1px;
        -webkit-text-stroke: 0px #482e6f;
        position: relative;
        text-stroke: 0px #482e6f;
        text-align: center;
        margin-top: 32px;

        img {
          position: absolute;
          width: 275px;
          left: 50%;
          top: 14px;
          transform: translateX(-50%);
          z-index: -1;
        }

        .desc {
          font-size: 16px;
          font-weight: 400;
          color: #3e4b66;
          margin-top: 8px;
        }
      }
    }
  }
}