@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.operation-record-modal {
  :global {
    .ant-modal {
      line-height: unset !important;
    }

    .ant-modal-title {
      font-weight: 600;
      font-size: 20px;
      color: #000000;
      line-height: 28px;
      text-align: center;
    }

    .ant-modal-content {
      padding: 32px 0px 16px !important;
      border-radius: 28px !important;

      .ant-modal-body {
        line-height: unset !important;
      }
    }
  }

  .scroll-box {
    max-height: 500px;
    overflow-y: auto;
    .prettier-scroll-bar();
    padding: 0 32px;

    .record-item {
      border-left: 1px dashed #C6BBD5;
      padding-left: 17px;
      position: relative;

      &::before {
        content: '';
        display: block;
        .size(12px, 12px);
        background: #ffffff;
        position: absolute;
        top: 0px;
        left: 0px;
        transform: translateX(-50%);
      }

      .date {

        font-weight: 600;
        font-size: 18px;
        color: #000000;
        line-height: 25px;
        position: relative;
        margin-bottom: 8px;

        &::before {
          content: '';
          display: block;
          .circle(12px);
          background: #E5D3FF;
          position: absolute;
          top: 6px;
          left: -17px;
          transform: translateX(-50%);
        }

        &::after {
          content: '';
          display: block;
          .circle(6px);
          background: #8533FF;
          position: absolute;
          top: 9px;
          left: -17px;
          transform: translateX(-50%);
        }
      }

      .event-item {
        margin-bottom: 12px;

        .time {
          margin-bottom: 5px;
          font-weight: 400;
          font-size: 13px;
          color: #999999;
          line-height: 18px;
          position: relative;

          &::before {
            content: '';
            display: block;
            background: #B8BBC0;
            .circle(6px);
            position: absolute;
            left: -17px;
            transform: translateX(-50%);
            top: 6px;
          }
        }

        .event-detail {
          background: #F4F6FA;
          border-radius: 8px;
          padding: 16px 20px;

          >.left {
            flex: 1;
            margin-right: 16px;
            overflow: hidden;

            .title {
              .flex-row();
              justify-content: flex-start;
              margin-bottom: 8px;

              span {
                font-weight: 600;
                font-size: 15px;
                color: #000000;
              }

              .icon {
                .size(20px);
                margin-right: 5px;
              }
            }

            .properties {
              .flex-row();

              span {
                font-weight: 400;
                font-size: 13px;
                color: #666666;
                display: block;
                flex: 1;
                .text-ellipsis()
              }

              .icon {
                .size(16px);
                margin-right: 5px;
              }
            }
          }

          .decry-count {
            padding: 4px 12px;
            background: linear-gradient(144deg, #F6ECFF 0%, #EFE5FF 100%);
            border-radius: 12px;
            border: 1px solid #EEE3FF;
            font-weight: 600;
            font-size: 12px;
            color: @color-primary;
          }
        }
      }
    }
  }
}