@import (reference) '../../styles/mixins.less';

.ame-spin {
  &.fullscreen {
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
