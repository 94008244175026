@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.buy-vip-dialog {
  :global {
    .ant-modal {
      line-height: unset !important;
    }

    .ant-modal-content {
      padding: 0px !important;
      border-radius: 28px !important;

      .ant-modal-body {
        line-height: unset !important;
      }
    }
  }

  .pay-dialog-content {
    .flex-row();
    height: 650px;

    .left {
      .size(284px, 100%);
      position: relative;
      background-image: url(https://assets.weibanzhushou.com/web/pt-web/pay-dialog_bg__left-v1.38dd39f96eab.png);
      background-size: 100% 100%;
      padding: 24px;
      .flex-col();
      justify-content: flex-start;

      .user-info {
        width: 100%;
        display: flex;
        align-items: center;

        > img {
          .size(36px);
          border-radius: 50%;
          margin-right: 12px;
        }

        .user-name {
          font-size: 13px;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.85);
          line-height: 20px;
          width: 130px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: keep-all;
        }

        .user-expired {
          margin-top: 2px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.45);
          .flex-row();
        }
      }

      .title {
        .size(179px, 56px);
        background-image: url(https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_bg__title.b08c04358013.png);
        background-size: 100% 100%;
        padding-left: 37px;
        padding-top: 8px;
        font-size: 16px;

        font-weight: 600;
        color: #ffcb5b;
        line-height: 22px;
        margin-top: 29px;
      }

      > .vip-desc {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        margin-top: 4px;
        text-align: center;
        opacity: 0.8;
      }

      > .line {
        width: 205px;
        height: 1px;
        margin: 16px 0 18px;
        background-image: url(https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_line.aab8aa7bda4e.png);
        background-size: 100% 100%;
      }

      > .vip-gift-list {
        .flex-row();
        flex-wrap: wrap;
        justify-content: flex-start;
        row-gap: 15px;
        max-height: 250px;
        overflow-y: auto;
        .prettier-scroll-bar(0, 0);

        .gift-item {
          width: 76px;
          max-width: 33.3%;
          .flex-col();
          padding: 0 3px;

          .icon {
            width: 32px;
            margin-bottom: 4px;
          }

          .gift-name {
            font-size: 12px;

            font-weight: 500;
            color: rgba(255, 255, 255, 0.75);
            line-height: 17px;
          }

          .gift-desc {
            font-size: 12px;

            font-weight: 400;
            color: rgba(255, 255, 255, 0.45);
            line-height: 17px;
            .text-overflow();
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    > .right {
      flex: 1;
      padding-top: 26px;
      height: 100%;

      > .tabs {
        .flex-row();
        padding: 0 40px;

        .tab {
          width: 50%;
          .flex-row();
          .clickable();
          position: relative;
          height: 48px;
          background: #f9f5fe;
          border: 1px solid #f9f5fe;
          border-radius: 12px 12px 0px 0px;

          &:hover {
            font-weight: 600;

            span {
              color: #333333;
            }
          }

          &.active {
            background: #ffffff;
            font-weight: 600;
            box-shadow: 0px -5px 12px 0px rgba(102, 102, 102, 0.15);
            border: 1px solid #ffffff;

            .path-active {
              display: unset;
            }

            span {
              color: #333333;
            }

            &::after {
              content: '';
              display: block;
              width: 100%;
              height: 4px;
              position: absolute;
              bottom: -5px;
              left: 0;
              background: #ffffff;
            }
          }

          .path-active {
            height: 7px;
            position: absolute;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
            display: none;
          }

          .tab-icon {
            width: 22px;
            margin-right: 6px;
            position: relative;
            top: -1px;
          }

          span {
            font-size: 16px;
            font-weight: 600;
            color: #666666;
          }
        }
      }

      > .scroll-box {
        height: 359px;
        overflow-y: auto;
        .prettier-scroll-bar();
        margin-top: 20px;
        padding-bottom: 5px;

        &.pro-v-box {
          > .benefits-box .benefit-item-box {
            background: #fffdf6;
            padding-top: 20px;

            .benefit-item .label {
              background: #fdf6e1;
              border-color: #f2f2f0;
              color: #887a56;
            }
          }

          > .func-list .func-item {
            .icon {
              background: #fff9ed;
              border: 1px solid #faf1da;
            }

            &:hover {
              .label {
                color: #a87500;
              }

              .intro {
                color: #cd8814;
              }
            }
          }
        }

        > .benefits-box {
          .flex-row();
          padding: 0 0 0 40px;
          justify-content: space-between;

          .benefit-item-box {
            // height: 76px;
            background: #fcfcfc;
            border-radius: 6px;
            border: 1px solid #e9e9e9;
            .flex-row();
            position: relative;

            .tag {
              position: absolute;
              left: 50%;
              top: -2px;
              transform: translateX(-50%);
              width: 326px;
              height: 28px;
            }

            .benefit-item {
              width: 121px;

              &:not(:last-child) .benefit-detail::after {
                content: '';
                display: block;
                position: absolute;
                width: 1px;
                height: 20px;
                background: #e9e9e9;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }

              // .flex-col();
              .benefit-detail {
                position: relative;
                .flex-row();
                width: 100%;
                padding: 17px 0;

                img {
                  width: 22px;
                  margin-right: 4px;
                }

                span {
                  font-size: 13px;
                  font-weight: 600;
                  color: #353535;
                }
              }

              .label {
                font-size: 12px;
                font-weight: 500;
                color: #7c869a;
                line-height: 20px;
                text-align: center;
                background: #f5f8fe;
                border-top: 1px solid #f2f2f2;
              }
            }
          }
        }

        > .func-list {
          padding: 0 40px;
          margin-top: 20px;

          &.pb {
            padding-bottom: 42px;
          }

          .func-item {
            display: flex;
            position: relative;

            &.active .active {
              display: unset;
            }

            .active {
              width: 28px;
              position: absolute;
              left: -35px;
              top: 23px;
              display: none;
            }

            &:not(:first-child) {
              margin-top: 24px;
            }

            &:hover {
              .label {
                color: @color-primary;
              }

              .intro {
                color: #9b5dff;
              }
            }

            .icon {
              .size(56px);
              margin-right: 15px;
              background: #f8f5ff;
              border-radius: 36px;
              opacity: 0.95;
              border: 1px solid #f7f3ff;
              padding: 10px;
            }

            .label {
              font-size: 14px;

              font-weight: 600;
              color: #333333;
              line-height: 20px;
              margin-bottom: 6px;
            }

            .intro {
              font-size: 12px;

              font-weight: 400;
              color: #999999;
              line-height: 20px;
            }
          }
        }
      }

      > .bottom {
        border-top: 1px solid #f2f2f2;
        padding: 16px 40px;
        .flex-row();
        flex-wrap: wrap;

        .selection-box {
          transition: all 0.3s;
          width: 106px;
          height: 126px;
          background: #fcfcfc;
          border-radius: 12px;
          border: 1px solid #e9e9e9;
          .clickable();
          color: #333333;
          padding: 24px 0 18px;
          margin-right: 16px;
          position: relative;

          > div {
            width: 100%;
            .flex-col;
            justify-content: flex-start;
            z-index: 2;
            position: absolute;
          }

          > .tips {
            width: 66px;
            position: absolute;
            right: -1px;
            top: -1px;

            img {
              width: 100%;
            }
          }

          > .discount-tips {
            width: 58px;
            position: absolute;
            right: -1px;
            top: -1px;

            img {
              width: 100%;
            }
          }

          &:hover {
            box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
            background: #faf5ff;

            .plan-active-bg {
              display: unset;
            }
          }

          &.active {
            box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
            border-color: transparent;
            color: @color-primary;

            .selection-box-last {
              color: @color-primary;
            }

            .plan-active-bg,
            .icon-plan-active {
              display: unset;
            }
          }

          .icon-plan-active {
            width: 22px;
            position: absolute;
            right: 1px;
            bottom: 0px;
            display: none;
          }

          .plan-active-bg {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: none;
          }

          .selection-box-last,
          .original-price {
            font-size: 13px;
            font-weight: 400;
            color: #b8b8b8;
            line-height: 18px;
          }

          .original-price {
            text-decoration: line-through;
          }

          .selection-box-first {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }

          .selection-box-middle {
            font-weight: 400;
            line-height: 24px;
            margin: 14px 0 12px;
            font-size: 17px;
            display: flex;
            align-items: baseline;

            .price-text {
              font-size: 26px;
              font-weight: 600;
            }
          }

          .pay-dialog-countdown {
            position: absolute;
            top: -64px;
            left: -27px;
            padding: 6px 10px;
            font-size: 12px;
            width: 155px;
            justify-content: flex-start;
            z-index: 3;
            flex-wrap: wrap;

            :global {
              .hy-count {
                .size(26px);
                margin: 0 3px;
                font-size: 16px;
              }

              .hy-prefix {
                width: 100%;
              }
            }

            &::after {
              left: 79px;
            }
          }
        }

        .payment-box {
          flex: 1;
          display: flex;
          align-items: center;
          background: linear-gradient(273deg, #fdfbff 0%, #faf5ff 100%);
          border-radius: 5px;
          position: relative;

          .channel-list {
            background: #f8f8f8;
            border-radius: 10px;
            border: 1px solid #e9e9e9;
            .flex-row();
            width: fit-content;

            > div {
              padding: 6px 10px;
              .flex-row();
              font-size: 12px;
              font-weight: 400;
              .clickable();
              color: #666666;
              position: relative;

              &.disable {
                cursor: unset;

                img,
                span {
                  opacity: 0.5;
                }

                &:hover {
                  .tips {
                    display: unset;
                  }
                }
              }

              img {
                width: 17px;
                margin-right: 5px;
              }

              &:not(.disable):hover {
                color: #333333;
              }

              &.active {
                background: #ffffff;
                box-shadow: 0px 0px 7px 0px rgba(92, 92, 92, 0.08);
                border-radius: 8px;
                color: #333333;
                font-weight: 600;
              }

              .tips {
                background: #ffffff;
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
                border-radius: 5px;
                padding: 8px;
                width: fit-content;
                font-size: 13px;
                font-weight: 400;
                color: #666666;
                line-height: 18px;
                position: absolute;
                left: 50%;
                top: -35px;
                transform: translate(-50%, -0%);
                word-break: keep-all;
                display: none;

                &::after {
                  content: '';
                  .pseudo-arrow-down(5px);
                  position: absolute;
                  bottom: -9px;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
            }
          }

          .qr-bg {
            .size(130px);
            position: absolute;
            left: 0;
            top: 0;
          }

          .qr-box {
            display: flex;
            justify-content: center;
            align-items: center;
            .size(130px);
            margin-right: 16px;
            // background-image: url(../../res/imgs/login-page/bg__qr.png);
            background-size: 100% 100%;
            position: relative;
            padding: 5px;
            z-index: 2;

            .reload-box {
              .size(119px, 120px);
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background: rgba(255, 255, 255, 0.94);
              .flex-col();
              cursor: pointer;

              img {
                width: 16px;
                margin-bottom: 4px;
              }

              span {
                font-size: 13px;
                font-weight: 400;
                color: #999999;
                line-height: 18px;
              }
            }
          }

          .vip-title-bold {
            font-size: 14px;
            font-weight: 600;
            color: #2c2c2c;
            line-height: 20px;
            margin-top: 10px;
          }

          .vip-total {
            font-size: 22px;
            font-weight: 600;
            color: @color-primary;
            line-height: 30px;
          }

          .subscription-pdf {
            font-size: 12px;
            font-weight: 400;
            color: #9d9d9d;
            line-height: 17px;
            margin-top: 4px;

            a {
              color: rgba(131, 47, 255, 1);
            }
          }
        }

        .subscription-tips {
          width: 100%;
          margin-top: 9px;
          font-size: 12px;

          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }
      }
    }
  }
}
