@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.online-header {
  .flex-row();
  justify-content: flex-start;
  height: 62px;
  background: rgba(255, 255, 255, 0.96);
  box-shadow: 0px 2px 12px 0px rgba(102, 102, 102, 0.08);
  padding: 0 32px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;

  .logo {
    .size(94px, 36px);
  }

  .tab-wrap {
    .flex-row();
    justify-content: flex-start;
    margin-left: 40px;
    gap: 40px;

    .tab {
      height: 22px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #444444;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      position: relative;
      .clickable();

      .flex-row();

      &.active {
        color: '#222';
        font-weight: 600;
      }

      &:hover {
        color: #222;
      }

      .underline {
        width: 20px;
        height: 3px;
        background: #2c2c2c;
        border-radius: 2px;

        position: absolute;
        left: 50%;
        bottom: -4px;
        transform: translateX(-50%);
      }

      .extension {
        width: 578px;
        height: 42px;
        box-shadow: inset 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
        background: url('~@/assets/default/online-extension-bg.png') no-repeat;
        background-size: contain;
        background-size: 578px;
        margin-left: 5px;
        padding-left: 20px;
        gap: 8px;

        .flex-row();
        justify-content: flex-start;

        &.marquee {
          width: 368px;

          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        overflow: hidden;
        word-break: keep-all;
        white-space: nowrap;

        .icon {
          .size(13px);
        }

        .text {
          display: flex;
          align-items: center;
          height: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #3f1978;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          gap: 8px;

          .icon {
            .size(13px);
          }
        }
      }
    }
  }

  .right {
    margin-left: auto;
    .flex-row();
    justify-content: space-between;

    .link {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #444;
      text-align: left;
      font-style: normal;
      .clickable();

      &:hover {
        color: #222;
      }

      &.register {
        width: 112px;
        height: 40px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        border: 1px solid @color-primary;
        .flex-row();

        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: @color-primary;

        text-align: left;
        font-style: normal;
      }
    }

    .avatar {
      position: relative;
      cursor: pointer;
      margin-left: 40px;

      .user-avatar {
        border-radius: 50%;
        width: 32px;
        height: 32px;
      }

      .rect {
        position: absolute;
        right: -5;
        bottom: 0;
        width: 5px;
        height: 5px;
      }
    }
  }
}