@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.buy-token-package-dialog {
  :global {

    .ant-modal {
      line-height: unset !important;
    }

    .ant-modal-content {
      padding: 24px 40px 64px 40px !important;
      border-radius: 28px !important;

      .ant-modal-body {
        line-height: unset !important;
      }
    }
  }

  .buy-token-package-content {
    >.title {
      font-size: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 28px;
      text-align: center;
      margin-bottom: 16px;
    }

    >.word-pack-tips-box {
      background: linear-gradient(136deg, #FEFDE0 0%, #FFFEEA 15%, #FEFDE0 100%);
      border-radius: 8px;
      border: 1px solid #FFF7C6;
      padding: 12px 20px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #723D15;
      margin-bottom: 24px;

      .bell-icon {
        width: 15px;
        margin-right: 4px;
        position: relative;
        top: -2px;
      }
    }

    .word-pack-plans {
      .flex-row();
      justify-content: space-between;

      li {
        width: 238px;
        height: 106px;
        background: #FCFCFC;
        border-radius: 12px;
        border: 1px solid #E9E9E9;
        .flex-col();
        .clickable();
        position: relative;
        color: #333333;

        .plan-active-bg,
        .icon-plan-active {
          display: none;
        }


        &.active {
          color: @color-primary;
          border-color: transparent;

          .plan-active-bg,
          .icon-plan-active {
            display: unset;
          }
        }

        &:hover {
          border-color: transparent;

          .plan-active-bg {
            display: unset;
          }
        }

        .plan-active-bg {
          width: 262px;
          position: absolute;
          left: 50%;
          top: -9px;
          transform: translateX(-50%);
          display: none;
        }

        .icon-plan-active {
          width: 22px;
          position: absolute;
          right: -1px;
          bottom: -2px;
          display: none;
        }

        .selection-box-middle {
          font-size: 17px;
          font-weight: 400;
          line-height: 24px;
          position: relative;
          z-index: 2;

          .price-text {
            font-size: 26px;
            font-weight: 600;
          }
        }

        .token-count {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          margin-top: 7px;
          position: relative;
          z-index: 2;
        }
      }
    }

    >.payment-box {
      background: linear-gradient(273deg, #FDFBFF 0%, #FAF5FF 100%);
      border-radius: 5px;
      .flex-row();
      margin-top: 24px;
      position: relative;
      align-items: flex-start;

      .qr-bg {
        .size(162px);
        position: absolute;
        left: 0;
        top: 0;
      }

      .qr-box {
        display: flex;
        justify-content: center;
        align-items: center;
        .size(162px);
        margin-right: 27px;
        background-size: 100% 100%;
        position: relative;
        padding: 5px;
        z-index: 2;

        .reload-box {
          .size(162px);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: rgba(255, 255, 255, 0.94);
          .flex-col();
          cursor: pointer;

          img {
            width: 16px;
            margin-bottom: 4px;
          }

          span {
            font-size: 13px;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
          }
        }

      }


      >.right {
        flex: 1;
        padding-top: 24px;

        .channel-list {
          background: #F8F8F8;
          border-radius: 10px;
          border: 1px solid #E9E9E9;
          .flex-row();
          width: fit-content;

          >div {
            padding: 7px 16px;
            .flex-row();
            font-size: 13px;
            font-weight: 400;
            .clickable();
            color: #666666;
            line-height: 18px;
            position: relative;

            img {
              width: 17px;
              margin-right: 5px;
            }

            &:hover {
              color: #333333;
            }

            &.active {
              background: #FFFFFF;
              box-shadow: 0px 0px 7px 0px rgba(92, 92, 92, 0.08);
              border-radius: 8px;
              color: #333333;
              font-weight: 600;
            }

          }
        }

        .vip-title-bold {
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #2C2C2C;
          line-height: 22px;
          margin-top: 12px;
        }

        .vip-total {
          font-size: 22px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: @color-primary;
          line-height: 30px;
          margin-top: 17px;
        }
      }
    }
  }

}