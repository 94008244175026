@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.vip-expired-tips-panel {
  position: relative;
  z-index: 2;

  .question-icon {
    .size(19px);
    padding: 3px;
    .clickable();
  }

  .tips-box {
    display: none;
    width: 379px;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 5px 32px 2px rgba(0, 0, 0, 0.12);
    border: 1px solid #EFEFF0;
    padding: 24px 24px 39px;
    position: absolute;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);

    &::before {
      content: '';
      display: block;
      position: absolute;
      .pseudo-arrow-up(6px);
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
    }

    .tips-title {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #2C2C2C;
      line-height: 20px;
      .flex-row();
      justify-content: flex-start;
      margin-bottom: 6px;

      &::before {
        content: '';
        display: block;
        width: 3px;
        height: 12px;
        background: #9955FE;
        border-radius: 1px;
        margin-right: 6px;
      }
    }

    .vip-tips {
      position: relative;
      margin-bottom: 19px;

      &.no-pro-text {
        height: 74px;
        overflow: hidden;
        border-radius: 13px;
      }

      >img {
        width: 100%;
      }

      >div {
        position: absolute;
        left: 73px;
        top: 17px;

        &.pro-box {
          .bold {
            color: #986BDA;
            background: linear-gradient(149deg, #AC61ED 0%, #A053F2 45%, @color-primary 100%, @color-primary 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .bold {
          font-size: 15px;
          font-family: AlibabaPuHuiTi_2_95_ExtraBold;
          color: #986BDA;
          line-height: 21px;
          background: linear-gradient(141deg, #D6990C 0%, #DE9841 45%, #C77F07 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .text {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
          margin-top: 2px;
        }
      }

      .text-pro {
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        position: absolute;
        left: 17px;
        bottom: 2px;
      }
    }

    .tips-content {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
    }
  }

  &:hover {
    .tips-box {
      display: block;
    }
  }
}