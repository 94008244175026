@import (reference) '../../styles/mixins.less';

.common-dialog-layout {}

.common-modal {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 24px 32px;
    }

    .ant-modal-footer {
      margin-top: 24px;
    }

    .ant-modal-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 20px;
      color: #000000;
    }

    .ant-modal-body {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
    }

    .ant-btn-default {
      border: 1px solid #dddddd;
      background: #ffffff;
      color: #333333;
      height: 44px;
      font-size: 16px;
      min-width: 120px;
      border-radius: 8px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;

      &:hover {
        border: 1px solid #cacaca !important;
        background: #ffffff !important;
        color: #2e2e2e !important;
      }
    }

    .ant-btn-primary {
      background: @color-primary;
      color: #ffffff;
      height: 44px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      min-width: 120px;

      margin-inline-start: 16px !important;

      &:hover {
        background: @color-primary-hover  !important;
        border: 1px solid @color-primary-hover  !important;
        color: #ffffff !important;
      }
    }
  }
}
