@import (reference) '../../styles/mixins.less';
@import '../../styles/common-class.less';

.discount-box-plan-b {
  .flex-col();
  width: 976px;
  justify-content: flex-start;
  position: relative;
  margin: 0 auto;
  padding-top: 82px;
  z-index: 10;
  line-height: normal;

  > .title {
    width: 653px;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    line-height: 25px;
    position: relative;

    .title-bg {
      width: 252px;
      position: absolute;
      top: -15px;
      right: 94px;
    }

    .discount {
      position: relative;
      right: -43px;
      top: -2px;
      .size(92px, 24px);
    }
  }

  > .center {
    margin-top: 24px;

    > .left {
      width: 301px;
      height: 506px;
      background: linear-gradient(180deg, #f4f7fb 0%, #ffffff 7%, #f4f8ff 31%, #eef4ff 100%);
      border-radius: 16px;
      border: 1px solid #ffffff;
      box-shadow: 0px 2px 32px 0px rgba(50, 50, 50, 0.08);

      .title {
        width: 100%;
        position: relative;
        height: 48px;
        // background-image: url(https://assets.weibanzhushou.com/web/pt-web/login-page_giftPlanB_bg__left-title.e0923a7df4db.png);
        background-image: url('~@/assets/default/login/icon__left-title.png');
        background-size: 100% 100%;
        // .left-title-bg {
        //   width: 100%;
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        // }

        .icon {
          width: 26px;
          margin-right: 8px;
        }

        span {
          font-size: 16px;
          font-weight: 600;
          color: #353535;
        }
      }

      .free-count-area {
        margin: 0 auto;
        margin-top: 20px;
        width: 269px;
        height: 126px;
        background: #ecf0ff;
        border-radius: 12px;
        border: 1px solid;
        border-image: linear-gradient(2deg, rgba(247, 249, 255, 1), rgba(251, 252, 255, 1)) 1 1;
        // padding: 20px 16px;
        // display: grid;
        // grid-template-columns: repeat(2, auto);
        // row-gap: 16px;
        padding: 16px 0;
        position: relative;

        .icon {
          .size(64px, 18px);
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
        }

        .line {
          .flex-row();
          justify-content: space-around;

          .item {
            flex: 1;
            .flex-col();

            .count {
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              font-size: 14px;
              color: #353535;
              line-height: 20px;
              text-align: right;
              font-style: normal;
            }

            .desc {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              line-height: 17px;
              text-align: right;
              font-style: normal;
            }
          }
        }
      }

      .free-wrap {
        width: 269px;
        background: linear-gradient(192deg, #f8faff 0%, #ffffff 100%);
        border-radius: 12px;
        border: 1px solid #ffffff;
        margin: 0 auto;
        margin-top: 16px;
        overflow: hidden;

        .translate {
          margin: 0 auto;

          padding: 28px 0px;

          background: linear-gradient(192deg, #f8faff 0%, #ffffff 100%);
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;

          position: relative;

          .tag {
            position: absolute;
            left: 0;
            top: 0;
            .size(49px, 18px);
          }

          .tag-right {
            position: absolute;
            right: 0;
            top: 0;
            .size(67px, 70px);
          }

          .line {
            margin-bottom: 24px;

            &:last-of-type {
              margin-bottom: 0px;
            }

            .flex-row();
            justify-content: space-around;

            .item {
              .flex-row();
              gap: 8px;

              .icon {
                .size(16px);
              }

              .text {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 13px;
                color: #353535;

                text-align: left;
                font-style: normal;
              }
            }
          }
        }

        .summary {
          margin: 0 auto;
          padding: 28px 0px;

          background: linear-gradient(180deg, #f8faff 0%, #ffffff 100%);
          border-radius: 12px 12px 0px 0px;

          position: relative;

          .tag {
            position: absolute;
            left: 0;
            top: 0;
            .size(49px, 18px);
          }

          .line {
            margin-bottom: 24px;
            margin-left: 29px;
            .flex-row();
            justify-content: flex-start;
            &:last-of-type {
              margin-bottom: 0px;
            }

            .item {
              .flex-row();
              gap: 8px;

              .icon {
                .size(16px);
              }

              .text {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 13px;
                color: #353535;

                text-align: left;
                font-style: normal;
              }
            }
          }
        }
      }
    }

    .bold {
      width: 100%;
      height: 28px;
      background: linear-gradient(
        290deg,
        rgba(226, 223, 223, 0) 0%,
        #f5f5f8 80%,
        rgba(234, 233, 239, 0.25) 100%
      );
      border-radius: 4px;
      .flex-row();
      font-size: 13px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #353535;
      margin-bottom: 20px;
      justify-content: flex-start;
      padding-left: 10px;

      &::before {
        content: '';
        display: inline-block;
        width: 3px;
        height: 12px;
        background: linear-gradient(157deg, #a05fff 0%, #ac74ff 100%);
        border-radius: 2px;
        margin-right: 10px;
      }
    }

    .func-item {
      margin-bottom: 24px;

      .flex-row {
        font-size: 13px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #353535;

        img {
          width: 14px;
          margin-right: 6px;
        }
      }

      .desc {
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        margin-top: 6px;
        white-space: nowrap;
      }
    }

    > .right {
      margin-left: 32px;
      width: 643px;
      height: 546px;
      background: linear-gradient(157deg, #a05fff 0%, #ac74ff 100%);
      border-radius: 24px;
      padding: 69px 8px 8px 8px;
      position: relative;
      overflow: hidden;

      &.pro-plus {
        background: #fce6a9;
      }

      .name {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 21px;
        .flex-row();
        .icon {
          .size(26px,12px);
        }
        gap: 9px;
        .version-name {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 19px;
          color: #725437;
          line-height: 26px;
          text-shadow: 0px 2px 32px rgba(0, 0, 0, 0.08);
          text-align: left;
          font-style: normal;
        }
      }

      > .right-bg {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      > .right-content {
        background: linear-gradient(360deg, #fcfaff 0%, #f8f4ff 100%);
        // box-shadow: 0px 2px 40px 0px rgba(28, 28, 28, 0.12);
        border-radius: 15px;
        width: 100%;
        height: 100%;
        // padding: 24px;
        position: relative;
        z-index: 2;

        box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.08), 0px 2px 40px 0px rgba(28, 28, 28, 0.12);
        border-radius: 15px;

        &.pro-plus {
          background: linear-gradient(#fefcf6 0%, #fcf8f1 100%);
        }

        > .left {
          margin-left: 28px;
          margin-top: 20px;
          margin-bottom: 28px;

          .pro-count-area {
            margin: 0 auto;

            width: 269px;
            height: 126px;
            background: linear-gradient(194deg, #eee4ff 0%, #e4daff 100%);

            box-shadow: 0px 2px 12px 2px rgba(172, 172, 172, 0.12);
            border-radius: 12px;
            border: 1px solid #fff;

            padding: 16px 0;
            position: relative;

            &.pro-plus {
              background: linear-gradient(194deg, #fff4e4 0%, #faedd2 100%);
            }

            .icon {
              .size(64px, 18px);
              position: absolute;
              left: 50%;
              top: 0;
              transform: translateX(-50%);
            }

            .line {
              .flex-row();
              justify-content: space-around;

              .item {
                .flex-col();
                flex: 1;

                &.pro-plus {
                  .count {
                    color: #725437;
                  }
                  .desc {
                    color: #a99d7e;
                  }
                }

                .count {
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 600;
                  font-size: 14px;
                  color: #6e1de5;
                  text-shadow: 0px 2px 32px rgba(0, 0, 0, 0.08);
                  line-height: 20px;
                  text-align: right;
                  font-style: normal;
                }

                .desc {
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 12px;
                  color: #a798bf;
                  line-height: 17px;
                  text-align: right;
                  font-style: normal;
                }
              }
            }
          }

          .wrap {
            background: linear-gradient(185deg, #f5f2ff 0%, #ffffff 100%);
            box-shadow: 0px 1px 16px 0px rgba(119, 119, 119, 0.06);
            border-radius: 12px;
            border: 1px solid #fff;
            margin-top: 16px;
            overflow: hidden;

            &.pro-plus {
              background: linear-gradient(184deg, #fff9f2 0%, #ffffff 100%);
            }

            .translate {
              width: 269px;
              margin: 0 auto;

              padding: 28px 0px;

              //   background: linear-gradient(185deg, #f5f2ff 0%, #ffffff 100%);
              //   box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.08),
              //     0px 1px 16px 0px rgba(119, 119, 119, 0.06);

              border-top-left-radius: 12px;
              border-top-right-radius: 12px;

              position: relative;

              .tag {
                position: absolute;
                left: 0;
                top: 0;
                .size(49px, 18px);
              }

              .tag-right {
                position: absolute;
                right: 0;
                top: 0;
                .size(67px, 70px);
              }

              .line {
                margin-bottom: 24px;

                &:last-of-type {
                  margin-bottom: 0px;
                }

                .flex-row();
                justify-content: space-around;

                .item {
                  .flex-row();
                  gap: 8px;

                  .icon {
                    .size(20px);
                  }

                  .text {
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 13px;
                    color: #353535;

                    text-align: left;
                    font-style: normal;
                  }
                }
              }
            }

            .summary {
              width: 269px;
              margin: 0 auto;
              padding: 28px 0px;
              background: linear-gradient(180deg, #f5f2ff 0%, #ffffff 100%);

              &.pro-plus {
                background: linear-gradient(180deg, #fff9f2 0%, #ffffff 100%);
              }

              //   background: linear-gradient(180deg, #f5f2ff 0%, #ffffff 100%);
              //   box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.08);
              box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.08);
              border-radius: 12px 12px 0px 0px;

              border-top: none;
              position: relative;

              .tag {
                position: absolute;
                left: 0;
                top: 0;
                .size(49px, 18px);
              }

              .line {
                margin-bottom: 24px;
                .flex-row();
                justify-content: flex-start;

                margin-left: 26px;
                &:last-of-type {
                  margin-bottom: 0px;
                }

                .item {
                  .flex-row();
                  gap: 8px;

                  .icon {
                    .size(20px);
                  }

                  .text {
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 13px;
                    color: #353535;
                    text-shadow: 0px 2px 32px rgba(0, 0, 0, 0.08);
                    text-align: left;
                    font-style: normal;
                  }
                }
              }
            }
          }
        }

        .right {
          width: 279px;
          height: 407px;
          background: #fff;
          border-radius: 16px;
          position: relative;

          margin-top: 31px;
          margin-right: 24px;

          .pro-plus {
            box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.08);
          }

          .title {
            .text {
              font-size: 14px;
              color: #4f3772;
              text-align: center;
              padding-top: 24px;
              font-weight: 600;

              &.pro-plus-text {
                color: #725437;
              }
            }

            img {
              width: 250px;
              position: absolute;
              top: 11px;
              left: 15px;
            }
          }

          .gift-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            row-gap: 20px;
            margin-top: 36px;
            padding: 0 15px;

            .gift-item {
              display: flex;
              flex-direction: column;
              width: 75px;
              align-items: center;

              > img {
                width: 42px;
                height: 42px;
                border-radius: 7px;
                object-fit: cover;
              }

              .gift-name {
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #353535;
                line-height: 17px;
                margin-top: 8px;
              }

              .gift-desc {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 17px;
                max-width: 100%;
                .text-ellipsis;
              }
            }
          }
        }
      }
    }
  }

  > .bottom {
    margin-top: 38px;
    position: relative;
    width: 100%;
    z-index: 2;

    .jump {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      position: absolute;
      top: 17px;
      right: 0;
      cursor: pointer;
      user-select: none;
      // z-index: 3;
    }

    .price-box {
      position: relative;
      margin-right: 40px;
      top: -9px;

      .price {
        font-size: 36px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #353535;
        line-height: 50px;
      }

      .sale-icon {
        width: 59px;
        position: absolute;
        right: -34px;
        top: 3px;
      }

      .unit {
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #353535;
        line-height: 17px;
        position: relative;
        top: -9px;
        left: 2px;
      }

      .original-price {
        font-size: 13px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        text-decoration: line-through;
        position: relative;
        top: -9px;
      }
    }

    .btn-box {
      position: relative;
      display: flex;

      .countdown-box {
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
        width: 300px;
      }

      button.pay-btn {
        width: 219px;
        height: 48px;
        background: @color-primary;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;
        user-select: none;
        border: none;
        .flex-row();

        &.pro-plus {
          background: #333232;
          &:hover {
            background: #262625;
          }
        }

        &:hover {
          background: @color-primary-hover;
        }

        img {
          width: 18px;
          margin-left: 7px;
        }
      }
    }
  }
}
