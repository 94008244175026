@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.footer-wrap {
  width: 100%;
  background: #fff;
  .online-footer {
    width: 100%;
    .flex-row();
    justify-content: space-between;
    align-items: flex-start;
    height: 265px;
    padding: 54px 122px;
    padding-bottom: 44px;
    background: #ffffff;
    box-shadow: 0px -1px 0px 0px #f2f2f2;

    .left {
      .flex-col();
      justify-content: flex-start;
      align-items: flex-start;
      .logo {
        width: 187px;
      }

      .desc {
        height: 21px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #000000;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        margin-top: 22px;
      }

      .copy-right {
        height: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        margin-top: 23px;
      }

      .info {
        height: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        margin-top: 18px;
        opacity: 0.6;
      }
    }

    .right {
      .flex-row();
      align-items: flex-start;
      gap: 89px;

      .list {
        .title {
          height: 25px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 18px;
          color: #22232f;
          line-height: 25px;
          text-align: left;
          font-style: normal;
          margin-bottom: 28px;
        }

        .item {
          height: 21px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #22232f;
          line-height: 21px;
          text-align: justify;
          font-style: normal;
          margin-bottom: 17px;
          position: relative;
          .clickable();

          &:hover {
            color: #222;
            text-decoration: underline;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .help-box {
            position: absolute;
            top: -145px;
            left: 50%;
            transform: translateX(-50%);
            width: 140px;
            height: 140px;
            padding: 2px;
            background: white;
            border-radius: 6px;
            box-shadow: 0px 2px 10px 0px rgba(93, 93, 93, 0.15);
            opacity: 0;
            transition: opacity 0.3s ease;
            visibility: hidden;
            z-index: 10;

            .qr-code {
              .size(100%);
            }

            &.visible {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .link-wrap {
    border-top: 1px solid #eeeeee;
    padding-top: 40px;
    padding-bottom: 48px;
    margin-left: 122px;
    margin-right: 122px;

    .link-title {
      height: 25px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #22232f;
      line-height: 25px;
      text-align: left;
      font-style: normal;
    }

    .links {
      margin-top: 28px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 40px;
      row-gap: 16px;

      .link {
        height: 21px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #22232f;
        line-height: 21px;
        text-align: justify;
        font-style: normal;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
