@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.new-user-discount-modal {
  :global{
    .ant-modal{
      // padding-bottom: 0;
      @media (max-height: 770px) {
        top: 50% !important;
        transform:translateY(-50%) ;
      }
    }
  }
  &.pro-v-modal .new-user-discount-bg{
    background: linear-gradient(180deg, #FFF2E6 0%, #FFFFFF 100%);
  }
  .new-user-discount-bg {
    width: 100%;
    height: 310px;
    background: 'linear-gradient( 180deg, #F0E6FF 0%, #FFFFFF 310px)';
    border-radius: 28px 28px 0 0;
    position: absolute;
    left: 0;
    top: 0;
  }
}