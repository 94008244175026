@import (reference) '../../styles/mixins.less';

body {
  .normalize();
}

.login-page {
  position: relative;
  width: 100%;
  height: 100%;

  .bg {
    width: 100%;
    height: 216px;
    background: #fdfbff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .bg-img {
    width: 100%;
    height: 225px;
    object-fit: cover;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 200px;
  }

  .login-box {
    width: 432px;
    height: 538px;
    background: #ffffff;
    box-shadow: 0px 2px 24px 0px rgba(245, 236, 255, 0.35);
    border-radius: 28px;
    border: 1px solid #efeff0;
    padding-top: 40px;
    position: relative;
    margin: 120px auto 0;
    z-index: 10;
    .flex-col();
    justify-content: flex-start;

    .title {
      font-size: 28px;
      font-weight: 400;
      color: #000000;
      line-height: 40px;
    }

    .sub-title {
      .flex-row();
      margin-top: 32px;

      .icon {
        .size(20px, 17px);
        object-fit: cover;
      }

      .text {
        font-size: 16px;
        font-weight: 600;
        color: @color-text;
        line-height: 22px;
        margin-left: 6px;
      }
    }

    .qr-code-box {
      width: 220px;
      height: 220px;
      background: #ffffff;
      border-radius: 5px;
      border: 2px solid #f5edff;
      position: relative;
      padding: 2px;
      margin-top: 24px;

      .loading-icon {
        .size(24px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }

      .qr-code {
        .size(100%);
        object-fit: contain;
      }

      .mask-box{
        .size(100%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, .95);
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 400;
        font-size: 13px;
        color: #353535;
        line-height: 18px;
        text-align: center;
      }
    }

    .tip-box {
      margin-top: 32px;
      width: 100%;
      .flex-col();

      .tip-text {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;

        a {
          color: #666666;

          &:hover {
            text-decoration: underline;
          }
        }

        &:last-child {
          margin-top: 10px;
        }
      }
      .tips {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #333333;

        .checkbox-btn {
          margin-right: 2px;
          padding: 4px;
          cursor: pointer;

          &.checkbox-active {
            .check-hover, .check-normal {
              display: none;
            }

            .check-active {
              display: block
            }
          }

          &:not(.checkbox-active):hover {
            .check-hover {
              display: block
            }

            .check-normal {
              display: none
            }
          }

          img {
            .size(14px);
            display: block;
          }

          .check-hover, .check-active {
            display: none;
          }
        }

        a {
          color: rgba(69, 154, 233, 1) !important;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .footer-box {
    text-align: center;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      position: relative;
      padding: 0 8px;

      &:hover {
        text-decoration: underline;
      }

      &:first-child {
        &::after {
          content: ' ';
          width: 1px;
          height: 12px;
          background: #999999;
          position: absolute;
          right: -1px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .discount-box {
    width: 936px;
    // height: 616px;
    background: #ffffff;
    box-shadow: 0px 6px 24px 0px rgba(124, 124, 124, 0.12);
    border-radius: 28px;
    border: 1px solid #efeff0;
    position: relative;
    margin: 120px auto 0;
    z-index: 10;
    align-items: stretch;

    .flex-col {
      align-items: center;
    }

    .gift-box {
      width: 282px;
      background: linear-gradient(180deg, #e9dbff 0%, #fffffe 100%);
      border-radius: 28px 0px 0px 28px;
      padding: 69px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      >.title {
        .size(169px, 56px);
        background-image: url(https://assets.weibanzhushou.com/web/pt-web/login-page_bg__gift-title.ee57ca5c871e.png);
        background-size: cover;
        font-size: 14px;
        color: #4f3772;
        text-align: center;
        padding-top: 10px;
        font-weight: 600;
      }

      >.desc {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5d5d5d;
        line-height: 17px;
        margin-top: 32px;
      }

      >.gift-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 20px;
        margin-top: 16px;
        padding: 0 19px;

        .gift-item {
          display: flex;
          flex-direction: column;
          width: 75px;

          >img {
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 7px;
            border: 1px solid #ffffff;
            object-fit: cover;
          }

          .gift-name {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #353535;
            line-height: 17px;
            margin-top: 8px;
          }

          .gift-desc {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            max-width: 100%;
            .text-ellipsis;
          }
        }
      }
    }

    .right {
      flex: 1;
      position: relative;
      padding-bottom: 20px;

      >.right-bg {
        width: 146px;
        position: absolute;
        right: 27px;
        top: 306px;
      }

      >.jump {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        position: absolute;
        right: 30px;
        bottom: 20px;
        cursor: pointer;
        user-select: none;
        z-index: 3;

        &:hover {
          color: #666666;
        }
      }

      >.right-content {
        position: relative;
        z-index: 2;
        padding-top: 34px;

        >.title {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #4f3772;
          line-height: 22px;
        }

        .price-box {
          width: 478px;
          background: linear-gradient(360deg, #fcfaff 0%, #f8f4ff 100%);
          box-shadow: 0px 2px 24px 0px rgba(28, 28, 28, 0.08);
          border-radius: 24px;
          border: 2px solid #9955fe;
          padding-bottom: 10px;
          position: relative;

          .price-title-bg {
            position: absolute;
            width: 478px;
            left: -2px;
            top: -2px;
          }

          >.title {
            .size(100%, 44px);
            text-align: center;
            line-height: 44px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            position: relative;
            z-index: 2;
          }

          .price-detail {
            position: relative;

            .discount-price {
              font-size: 48px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #820cfa;
              line-height: 67px;
            }

            .unit {
              font-size: 21px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #820cfa;
              line-height: 29px;
              margin-left: -4px;
            }

            .original-price {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 20px;
              position: absolute;
              right: -94px;
              bottom: 14px;
              text-decoration: line-through;
            }

            .discount {
              width: 88px;
              height: 24px;
              background: linear-gradient(157deg, #a05fff 0%, #ac74ff 100%);
              border-radius: 12px 12px 12px 2px;
              font-size: 13px;
              color: #ffffff;
              line-height: 24px;
              text-align: center;
              position: absolute;
              top: 0px;
              right: -42px;
            }
          }

          .price-desc {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9757f8;
            line-height: 22px;
            margin-top: 2px;
          }

          .vip-func-list {
            width: 428px;
            background: #f7f3ff;
            border-radius: 11px;
            position: relative;
            margin-top: 44px;

            >p {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #5d5d5d;
              line-height: 17px;
              background: linear-gradient(270deg,
                  rgba(233, 219, 255, 0.25) 0%,
                  #eadbff 52%,
                  rgba(234, 219, 255, 0.25) 100%);
              border-radius: 4px;
              padding: 3px 18px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: -14px;
            }

            .func-list {
              display: flex;
              justify-content: space-between;
              padding: 16px 24px;

              .func-col {
                .func-item {
                  .flex-row();
                  justify-content: flex-start;

                  &:first-child {
                    margin-bottom: 10px;
                  }

                  >img {
                    width: 24px;
                    margin-right: 8px;
                  }

                  span {
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #353535;
                  }
                }
              }
            }
          }

          >.desc {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            margin-top: 10px;
          }
        }

        .btn-box {
          margin-top: 107px;
          display: flex;
          position: relative;
          width: 100%;
          justify-content: center;

          .countdown-box {
            position: absolute;
            top: -60px;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .invoice-desc {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: 12px;
          .flex-row();

          >img {
            margin-right: 4px;
            width: 13px;
          }
        }
      }
    }
  }

  .phone-wrap {
    position: relative;
    margin: 120px auto 0;
    .flex-col();
    justify-content: flex-start;
    padding-top: 40px;

    .padding-wrap {
      padding: 40px 76px 66px;
      background: #ffffff;
      box-shadow: 0px 2px 24px 0px rgba(245, 236, 255, 0.35);
      border-radius: 28px;
      border: 1px solid #efeff0;
    }
  }

  // button.pay-btn {
  //   width: 219px;
  //   height: 48px;
  //   background: @color-primary;
  //   box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  //   border-radius: 12px;
  //   font-size: 16px;
  //   font-weight: 600;
  //   color: #ffffff;
  //   cursor: pointer;
  //   user-select: none;
  //   border: none;
  //   .flex-row();

  //   &:hover {
  //     background: @color-primary-hover;
  //   }

  //   img {
  //     width: 18px;
  //     margin-left: 7px;
  //   }
  // }

  // .discount-box-plan-b {
  //   .flex-col();
  //   width: 976px;
  //   justify-content: flex-start;
  //   position: relative;
  //   margin: 0 auto;
  //   padding-top: 82px;
  //   z-index: 10;

  //   > .title {
  //     font-size: 18px;
  //     font-weight: 600;
  //     color: #000000;
  //     line-height: 25px;
  //     width: fit-content;
  //     position: relative;

  //     .title-bg {
  //       width: 252px;
  //       position: absolute;
  //       top: -15px;
  //       right: -42px;
  //     }
  //   }

  //   > .center {
  //     margin-top: 24px;

  //     > .left {
  //       width: 301px;
  //       height: 506px;
  //       background: #fbfbfb;
  //       border-radius: 16px;
  //       border: 2px solid #ffffff;

  //       .title {
  //         width: 100%;
  //         position: relative;
  //         height: 48px;
  //         background-image: url(https://assets.weibanzhushou.com/web/pt-web/login-page_giftPlanB_bg__left-title.e0923a7df4db.png);
  //         background-size: 100% 100%;
  //         // .left-title-bg {
  //         //   width: 100%;
  //         //   position: absolute;
  //         //   left: 0;
  //         //   top: 0;
  //         // }

  //         .icon {
  //           width: 26px;
  //           margin-right: 8px;
  //         }

  //         span {
  //           font-size: 16px;
  //           font-weight: 600;
  //           color: #353535;
  //         }
  //       }

  //       > div {
  //         padding: 16px 24px;
  //       }
  //     }

  //     .bold {
  //       width: 100%;
  //       height: 28px;
  //       background: linear-gradient(
  //         290deg,
  //         rgba(226, 223, 223, 0) 0%,
  //         #f5f5f8 80%,
  //         rgba(234, 233, 239, 0.25) 100%
  //       );
  //       border-radius: 4px;
  //       .flex-row();
  //       font-size: 13px;
  //       font-family: PingFangSC, PingFang SC;
  //       font-weight: 600;
  //       color: #353535;
  //       margin-bottom: 20px;
  //       justify-content: flex-start;
  //       padding-left: 10px;

  //       &::before {
  //         content: '';
  //         display: inline-block;
  //         width: 3px;
  //         height: 12px;
  //         background: linear-gradient(157deg, #a05fff 0%, #ac74ff 100%);
  //         border-radius: 2px;
  //         margin-right: 10px;
  //       }
  //     }

  //     .func-item {
  //       margin-bottom: 24px;

  //       .flex-row {
  //         font-size: 13px;
  //         font-family: PingFangSC, PingFang SC;
  //         font-weight: 600;
  //         color: #353535;

  //         img {
  //           width: 14px;
  //           margin-right: 6px;
  //         }
  //       }

  //       .desc {
  //         font-size: 12px;
  //         font-family: PingFangSC, PingFang SC;
  //         font-weight: 400;
  //         color: #999999;
  //         line-height: 17px;
  //         margin-top: 6px;
  //         white-space: nowrap;
  //       }
  //     }

  //     > .right {
  //       margin-left: 32px;
  //       width: 643px;
  //       height: 546px;
  //       background: linear-gradient(157deg, #a05fff 0%, #ac74ff 100%);
  //       border-radius: 24px;
  //       padding: 69px 8px 8px 8px;
  //       position: relative;

  //       > .right-bg {
  //         width: 100%;
  //         position: absolute;
  //         top: 0;
  //         left: 0;
  //       }

  //       > .right-content {
  //         background: linear-gradient(360deg, #fcfaff 0%, #f8f4ff 100%);
  //         box-shadow: 0px 2px 40px 0px rgba(28, 28, 28, 0.12);
  //         border-radius: 15px;
  //         width: 100%;
  //         height: 100%;
  //         padding: 24px;
  //         position: relative;
  //         z-index: 2;

  //         > .left {
  //           .bold {
  //             color: @color-primary;
  //             background: linear-gradient(
  //               280deg,
  //               rgba(233, 219, 255, 0) 0%,
  //               #eadbff 80%,
  //               rgba(234, 219, 255, 0.25) 100%
  //             );
  //           }
  //         }

  //         .right {
  //           width: 279px;
  //           height: 407px;
  //           background: #fdfcff;
  //           border-radius: 16px;
  //           position: relative;

  //           .title {
  //             font-size: 14px;
  //             color: #4f3772;
  //             text-align: center;
  //             padding-top: 24px;
  //             font-weight: 600;

  //             img {
  //               width: 250px;
  //               position: absolute;
  //               top: 11px;
  //               left: 15px;
  //             }
  //           }

  //           .gift-list {
  //             display: flex;
  //             flex-wrap: wrap;
  //             justify-content: space-between;
  //             row-gap: 20px;
  //             margin-top: 32px;
  //             padding: 0 15px;

  //             .gift-item {
  //               display: flex;
  //               flex-direction: column;
  //               width: 75px;
  //               align-items: center;

  //               > img {
  //                 width: 42px;
  //                 height: 42px;
  //                 border-radius: 7px;
  //                 object-fit: cover;
  //               }

  //               .gift-name {
  //                 font-size: 12px;
  //                 font-family: PingFangSC-Medium, PingFang SC;
  //                 font-weight: 500;
  //                 color: #353535;
  //                 line-height: 17px;
  //                 margin-top: 8px;
  //               }

  //               .gift-desc {
  //                 font-size: 12px;
  //                 font-family: PingFangSC-Regular, PingFang SC;
  //                 font-weight: 400;
  //                 color: #999999;
  //                 line-height: 17px;
  //                 max-width: 100%;
  //                 .text-ellipsis;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   > .bottom {
  //     margin-top: 24px;
  //     position: relative;
  //     width: 100%;
  //     z-index: 2;

  //     .jump {
  //       font-size: 14px;
  //       font-family: PingFangSC, PingFang SC;
  //       font-weight: 400;
  //       color: #999999;
  //       line-height: 20px;
  //       position: absolute;
  //       top: 17px;
  //       right: 0;
  //       cursor: pointer;
  //       user-select: none;
  //       // z-index: 3;
  //     }

  //     .price-box {
  //       position: relative;
  //       margin-right: 40px;
  //       top: -9px;

  //       .price {
  //         font-size: 36px;
  //         font-family: PingFangSC, PingFang SC;
  //         font-weight: 600;
  //         color: #353535;
  //         line-height: 50px;
  //       }

  //       .sale-icon {
  //         width: 59px;
  //         position: absolute;
  //         right: -34px;
  //         top: 3px;
  //       }

  //       .unit {
  //         font-size: 12px;
  //         font-family: PingFangSC, PingFang SC;
  //         font-weight: 600;
  //         color: #353535;
  //         line-height: 17px;
  //         position: relative;
  //         top: -9px;
  //         left: 2px;
  //       }

  //       .original-price {
  //         font-size: 13px;
  //         font-family: PingFangSC, PingFang SC;
  //         font-weight: 400;
  //         color: #999999;
  //         line-height: 18px;
  //         text-decoration: line-through;
  //         position: relative;
  //         top: -9px;
  //       }
  //     }

  //     .btn-box {
  //       position: relative;
  //       display: flex;

  //       .countdown-box {
  //         position: absolute;
  //         top: -60px;
  //         left: 50%;
  //         transform: translateX(-50%);
  //         width: 300px;
  //       }
  //     }
  //   }
  // }
}