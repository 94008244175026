@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.pay-success-dialog {
  :global {
    .ant-modal-content {
      padding: 0px !important;
      border-radius: 28px !important;
    }
  }

  .flex-row() {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pay-success-dialog-content {
    padding: 0 0 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      line-height: 28px;
      margin-top: 24px;
    }

    .success-icon {
      margin-top: 44px;
      .size(187px, 143px);
    }

    .tips-title {
      color: #333333;
      font-size: 14px;
      font-weight: 600;
    }

    .tips-content {
      color: #666666;
      font-size: 14px;
      margin-top: 8px;
    }

    .desc {
      margin-top: 24px;
      font-size: 13px;
      font-weight: 400;
      color: #999999;

      >img {
        width: 13px;
        margin-right: 8px;
      }
    }

    .btn-box {
      margin-top: 16px;
      display: flex;

      .gift-receive-btn {
        width: 192px;
        height: 44px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;

        color: #000000;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;
        border: 1px solid #DDDDDD;

        &:hover {
          .gift-qr-box {
            display: flex;
          }
        }

        position: relative;

        .gift-qr-box {
          width: 270px;
          height: 318px;
          background: linear-gradient(180deg, #F3EFFA 0%, #FFFFFF 100%);
          box-shadow: 0px 5px 32px 2px rgba(0, 0, 0, 0.12);
          border-radius: 20px;
          border: 1px solid #FFFFFF;
          .flex-col();
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 48px;
          display: none;

          .qr-with-bg {
            margin-top: 16px;
            .size(190px, 188px);
            .flex-col();
            position: relative;

            .qr-bg {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }

            img.qr-code {
              width: 182px;
              position: relative;
              z-index: 2;
            }

            .loading-icon {
              .size(24px);
            }
          }

          .qr-t {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2C;
            line-height: 22px;
          }
        }
      }

      .btn {
        width: 192px;
        height: 44px;
        background: @color-primary;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background: @color-primary-hover;
        }
      }

    }

  }

}