@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.number-check {
  width: 376px;
  background: #fff;

  .title {
    height: 40px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 28px;
    color: #000000;
    line-height: 40px;
    text-align: center;
    font-style: normal;
  }

  .number-wrap {
    position: relative;
    margin-top: 40px;

    .input {
      width: 100%;
      height: 54px;
      background: #f7f9fa;
      border-radius: 10px;
      padding: 16px 20px;
      padding-right: 39px;
      outline: none;
      border: none;

      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      text-align: left;
      font-style: normal;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      -moz-appearance: textfield;
      appearance: textfield;

      &::placeholder {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #999999;
        text-align: left;
        font-style: normal;
      }
    }

    .err {
      position: absolute;
      left: 0;
      bottom: -21px;
      height: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #f03939;
      line-height: 18px;
      text-align: left;
      font-style: normal;
    }

    .clear {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 19px;
      .size(18px);
      .clickable();
      background-image: url('~@/assets/default/clear_number.png');
      background-size: 18px 18px;
      background-repeat: no-repeat;

      &:hover {
        background-image: url('~@/assets/default/clear_number_hover.png');
      }
    }
  }

  .confirm {
    .flex-row();
    height: 54px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #b27dff;
    margin-top: 36px;
    .clickable();

    &:hover {
      .text {
        color: #7d2cf5;
      }

      background: #fcfaff;
      border-radius: 10px;
      border: 1px solid @color-primary;
    }

    gap: 4px;

    .icon {
      .size(20px);
    }

    .text {
      height: 24px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: @color-primary;
      line-height: 24px;
      text-align: left;
      font-style: normal;
    }
  }

  .tip {
    height: 36px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    margin-top: 20px;
  }

  .footer-box {
    margin-top: 47px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      position: relative;
      padding: 0 8px;

      &:hover {
        text-decoration: underline;
      }

      &:first-child {
        &::after {
          content: ' ';
          width: 1px;
          height: 12px;
          background: #999999;
          position: absolute;
          right: -1px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
