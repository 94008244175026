@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

// body {
//   .pretty-scroll-bar();
// }

.online-translation {
  //   min-height: 100vh;
  height: 100%;
  padding-top: 62px;
  // background: linear-gradient(180deg, #fafaff 0%, #f3f3ff 100%);
  background: #f3f3ff;
  .prettier-scroll-bar();
  .flex-col();
  justify-content: flex-start;

  .translation-box {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    .flex-row();
    justify-content: flex-start;
    gap: 20px;

    .right {
      width: 375px;
      height: 531px;
      background: linear-gradient(174deg, #f7f7ff 0%, #f0f0ff 100%);
      box-shadow: inset 0px 0px 24px 0px #edeeff;
      border-radius: 12px;
      border: 2px solid #ffffff;
      padding: 16px;
      .flex-col();
      justify-content: flex-start;

      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #7854f0;
        line-height: 20px;
        text-align: center;
        font-style: normal;
      }

      .header {
        .flex-row();
        justify-content: flex-start;

        .icon {
          .size(21px);
        }

        .text {
          margin-left: 3px;
          height: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #221c33;

          text-align: left;
          font-style: normal;
        }

        .btn {
          margin-left: auto;
          width: 94px;
          height: 28px;
          background: linear-gradient(180deg, #9389fa 0%, #714ef6 100%);
          box-shadow: inset 0px 0px 4px 0px rgba(255, 255, 255, 0.37);
          border-radius: 15px;
          .flex-row();
          gap: 7px;
          .clickable();

          &:hover {
            background: linear-gradient(180deg, #9389ef 0%, #6e4feb 100%);
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12),
              inset 0px 0px 4px 0px rgba(255, 255, 255, 0.37);
          }

          .text {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 13px;
            color: #ffffff;

            text-align: left;
            font-style: normal;
          }

          .arrow {
            .size(12px, 9px);
          }
        }
      }

      .card {
        width: 100%;
        height: 113px;
        border-radius: 12px;
        padding-top: 12px;
        padding-left: 17px;
        padding-right: 11px;
        padding-bottom: 24px;
        margin-top: 14px;
        background: url('~@/assets/default/bg__pdf.png') no-repeat;
        background-size: 100% 100%;

        .desc {
          margin-top: 10px;
          font-family: AppleColorEmoji;
          font-size: 13px;
          color: #6a687d;
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }
      }

      .card-bottom {
        margin-top: 15px;
        padding: 12px 9px;
        width: 100%;
        height: 341px;
        background: url('~@/assets/default/bg__huiyi.png') no-repeat;
        background-size: 100% 100%;

        border-radius: 12px;

        .detail {
          margin-top: 11px;
          padding: 14px 10px;
          height: 286px;
          background: linear-gradient(174deg, #f3f2ff 0%, #f8f8ff 100%);
          border-radius: 12px;

          .title {
            margin-left: 2px;
            .flex-row();
            justify-content: flex-start;

            .icon {
              .size(20px);
            }

            .desc {
              margin-left: 2px;
              height: 20px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 13px;
              color: #6a687d;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
          }

          .list {
            margin-top: 13px;

            .item {
              .flex-row();
              align-items: flex-start;
              justify-content: flex-start;
              gap: 3px;

              margin-bottom: 8px;

              &:last-child {
                margin-bottom: 0;
              }

              .icon {
                position: relative;
                top: 3px;
                .size(14px);
              }

              .text {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 13px;
                color: #6a687d;
                line-height: 20px;
                text-align: left;
                font-style: normal;

                .pre {
                  font-weight: 600;
                  color: #211e28;
                }
              }
            }
          }

          .last {
            margin-top: 13px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 13px;
            color: #6a687d;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }
        }
      }
    }
  }

  .description-box {
    width: 1366px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 101px;
    .flex-col();
    justify-content: flex-start;

    .title {
      position: relative;
      margin-bottom: 30px;

      .text {
        width: 336px;
        height: 50px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 36px;
        color: #353535;
        line-height: 50px;
        text-align: right;
        font-style: normal;
      }

      .line {
        .size(249px, 12px);
        position: absolute;
        left: 90px;
        bottom: -13px;
      }
    }

    .content-card {
      width: 100%;
      .flex-row();
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 100px;

      .img {
        .size(756px, 648px);
      }

      .desc {
        // width: 820px;
        // height: 648px;
        padding-top: 80px;
        .flex-col();
        align-items: flex-start;
        justify-content: flex-start;

        .icon-wrap {
          width: 60px;
          height: 60px;
          background: linear-gradient(174deg, #ffffff 0%, #f0f0ff 100%);
          box-shadow: inset 0px 0px 24px 0px #edeeff;
          border-radius: 12px;
          border: 2px solid #fff;
          .flex-row();

          .icon {
            .size(41px);
          }
        }

        .info {
          .tag {
            height: 41px;
            background: #eae7ff;
            border-radius: 2px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 22px;
            color: #222222;

            text-align: left;
            font-style: normal;
            .flex-row();
            justify-content: flex-start;
            padding-left: 16px;
            position: relative;

            .line {
              position: absolute;
              left: 0;
              top: 0;
              width: 4px;
              height: 41px;
              background: linear-gradient(180deg, #9389fa 0%, #714ef6 100%);
              box-shadow: inset 0px 1px 6px 0px rgba(255, 255, 255, 0.37);
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
            }
          }

          .text {
            margin-top: 20px;
            width: 459px;

            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #555555;
            line-height: 22px;
            text-align: left;
            font-style: normal;
          }
        }

        .install {
          margin-top: 44px;
          width: 152px;
          height: 48px;
          background: #2a2c3f;
          border-radius: 30px;
          .flex-row();
          gap: 11px;

          .clickable();

          .icon {
            .size(13px);
          }

          &:hover {
            background: #282a3c;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
          }

          font-family: PingFangSC,
          PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
}


.left.translation-root {
  width: 917px;
  height: 531px;
  background: linear-gradient(174deg, #f7f7ff 0%, #f0f0ff 100%);
  box-shadow: 0px 2px 24px 4px rgba(134, 134, 134, 0.08), inset 0px 0px 24px 0px #edeeff;
  border-radius: 16px;
  border: 2px solid #ffffff;
  backdrop-filter: blur(10px);
  padding: 16px;
  padding-top: 18px;
  display: flex;
  flex-direction: column;

  .language {
    .flex-row();
    justify-content: flex-start;

    .language-item {
      .flex-row();

      .icon {
        .size(18px);
      }

      .label {
        width: 70px;
        height: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        text-align: left;
        font-style: normal;
      }

      .select-lang {
        .flex-row();
        gap: 12px;
        .clickable();

        .text {
          max-width: 140px;
          height: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #1c1e22;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          .text-ellipsis();
        }

        .arrow {
          .size(14px);
        }
      }
    }

    .switch {
      margin: 0 30px;
      .size(16px, 13px);
      .clickable();
    }
  }

  .input-area {
    flex: 1;
    .flex-row();
    justify-content: flex-start;
    margin-top: 17px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.05);
    border-radius: 10px;
    border: 1px solid #f6f6f6;
    overflow: hidden;
    .source {
      flex: 1;
      height: 100%;
      position: relative;

      textarea {
        width: 100%;
        height: 100%;
        background-color: transparent;
        resize: none;
        .pretty-scroll-bar(3px);
        border: none;
        outline: none;
        padding: 16px 20px;
        overscroll-behavior: none;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        text-align: left;
        font-style: normal;

        &::placeholder {
          color: #c6c6c6;
          font-size: 20px;
        }
      }

      .clear {
        .size(14px);
        position: absolute;
        right: 12px;
        top: 22px;
        .clickable();
      }
    }

    .target {
      flex: 1;
      height: 100%;
      background: #f9fafb;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      position: relative;

      .result {
        width: 100%;
        height: 100%;
        padding: 16px 20px;
        padding-right: 34px;
        overflow-y: auto;
        .pretty-scroll-bar(3px);
        overscroll-behavior: none;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #222222;
        line-height: 25px;
        text-align: left;
        font-style: normal;
        white-space: pre-wrap;

        .dot {
          font-size: 1em;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          margin: 0;
          padding: 0 3px;
          position: relative;
          width: 1px;
          animation: 1200ms cubic-bezier(0.34, 1.56, 0.64, 1) 0s infinite normal forwards dot-bounce;
          display: inline-block;
        }

        .dot-2 {
          animation-delay: 40ms;
        }

        .dot-3 {
          animation-delay: 60ms;
        }

        @keyframes dot-bounce {
          0% {
            transform: translateY(0px);
          }

          50% {
            transform: translateY(-8px);
          }

          100% {
            transform: translateY(0px);
          }
        }
      }

      .copy {
        .flex-row();
        .size(24px);
        border-radius: 50%;
        background: #ecedf4;

        position: absolute;
        right: 12px;
        top: 17px;
        .clickable();

        &:hover {
          background: #e6e7ee;
        }

        .icon {
          .size(11px, 12px);
        }
      }
    }
  }
}