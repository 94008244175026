@import (reference) '../../styles/mixins.less';

.loading-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.global-layout {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  // padding-bottom: 28px;
  position: relative;

  &.hide-page-footer {
    padding-bottom: 0;
  }

  .page-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    .pretty-scroll-bar(4px);
  }

  .page-footer {
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 106px;
    font-size: 12px;
    font-weight: 400;
    color: #555555;
    line-height: 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -1px 0px 0px #f2f2f2;
    z-index: 10;
    flex-wrap: nowrap;

    .link-btn {
      color: #555555;
      .clickable();
      word-break: keep-all;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }

    .company-intro {
      position: relative;

      &:hover {
        .company-intro-detail-wrapper {
          left: 50%;
          opacity: 1;
          transform: translateX(-50%);
        }
      }

      .company-intro-detail-wrapper {
        position: absolute;
        top: -216px;
        left: 10000px;
        opacity: 0;
        transition: opacity 0.3s ease;
        padding-bottom: 14px;
      }

      .company-intro-detail {
        background: white;
        width: 394px;
        height: 216px;
        border-radius: 4px;
        padding: 16px 24px;
        box-shadow: 0px 2px 10px 0px rgba(93, 93, 93, 0.25);
        display: inline-block;
        img {
          width: 166px;
          margin-bottom: 10px;
        }

        .divider-h {
          width: 345px;
          height: 1px;
          border: 1px dashed #e8e8e8;
        }

        .sub-title {
          margin-top: 12px;
          display: flex;
          align-items: center;
          margin-right: 6px;
          .divider {
            width: 3px;
            height: 12px;
            background: #dddddd;
            border-radius: 2px;
            margin-right: 6px;
          }
        }
        .body {
          margin-top: 12px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          // font-weight: 600;
          color: #333333;
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .page-footer {
      padding: 0 80px;
    }
  }

  @media screen and (max-width: 1280px) {
    .feedback-email {
      display: none;
    }
  }

  @media screen and (max-width: 1150px) {
    .contact-phone {
      display: none;
    }
  }
}
