@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.pdf-limit-modal {
  :global {
    .ant-modal-content {
      padding: 0;
      border-radius: 20px
    }
  }

  .bg {
    width: 364px;
    height: 254px;
    background: linear-gradient(180deg, #F0E6FF 0%, #FFFFFF 100%);
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 20px 20px 0 0
  }

  .content {
    .flex-col();
    position: relative;
    z-index: 1;
    padding: 40px 0;

    .cover {
      height: 120px;
      margin-bottom: 30px;
    }

    .bold {
      font-weight: 600;
      font-size: 16px;
      color: #000000;
      line-height: 22px;
      margin-bottom: 9px;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      margin-bottom: 24px;
      text-align: center;
    }

    .btn {
      width: 277px;
      height: 44px;
      background: #832FFF;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: @color-primary-hover;
        color: white;
      }

      .icon {
        width: 15px;
        margin-left: 6px;
      }
    }
  }
}